import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-start"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"borderless":""}},[_c(VCardTitle,{staticClass:"text-h5 text-md-h4 flex-nowrap align-start"},[(_vm.selectedItem)?_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"mr-3"},[_c(VBtn,{attrs:{"fab":""},on:{"click":function () { return (_vm.selectedItem = null); }}},[_c(VIcon,[_vm._v("$vuetify.icons.arrowLeft")])],1)],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedItem.title)}})],1):_c('div',[_vm._v(" Einstellungen ")])]),_c(VCardText,{staticClass:"text-body-1 text--primary"},[_c(VContainer,{staticClass:"px-0 pb-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedItem),expression:"!selectedItem"}],attrs:{"cols":"auto"}},[_c(VList,{staticClass:"py-0"},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,staticClass:"px-0",on:{"click":function () { if (item.action) { item.action(); } else { _vm.selectedItem = item; } }}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"large":""},domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6 font-weight-semibold text-wrap",domProps:{"innerHTML":_vm._s(item.title)}})],1),_c(VListItemAction,[(item.component)?_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("$vuetify.icons.playArrow")])],1):_vm._e()],1)],1)}),1)],1),(_vm.selectedItem)?_c(VCol,[_c(_vm.selectedItem.component,{tag:"component",attrs:{"buttonLabel":"Wechseln"},on:{"switchDone":_vm.switchIkDone}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }