<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                <v-icon v-if="newStatus ===2" color="primary" large left>mdi-lock-open</v-icon>
                <v-icon v-if="newStatus ===3" color="primary" large left>mdi-lock</v-icon>
                <v-icon v-if="newStatus ===4" color="primary" large left>$vuetify.icons.delete</v-icon>
                Benutzerkonto{{'\xa0'}}
                <span v-if="newStatus ===2">aktivieren</span>
                <span v-if="newStatus ===3">deaktivieren</span>
                <span v-if="newStatus ===4">löschen</span>
            </v-card-title>
            <v-card-text class="text-body-1 text--primary">
                <p v-if="userItem" class="text-justify text-hyphenate mb-0">
                    Sind Sie sicher, dass Sie das Konto mit Loginnamen {{userItem.loginname}}{{'\xa0'}}
                    <span v-if="newStatus ===2">aktivieren</span>
                    <span v-if="newStatus ===3">deaktivieren</span>
                    <span v-if="newStatus ===4">löschen</span>
                    wollen?
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="onOk">
                    Ja
                </v-btn>
                <v-btn color="primary" @click="onCancel">
                    Nein
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'DialogBenutzerStatusAenderung',
        props: {
            showDialog: true, 
            userItem: {},
            newStatus: null,
        },
        methods: {
            onOk() {

                this.$emit('dialogChangeState', true) 
            },

            onCancel() {
                this.$emit('dialogChangeState', false) 
            }
        },
    }
</script>