<template>
    <v-container v-if="$store.state.defCache" fluid class="px-4">
        <v-form v-model="isValid" ref="bvform">
            <v-row class="align-center">
                <v-col cols="12" sm="2" md="2">
                    <v-select label="Anrede" v-model="benutzer.anrede"
                              :items="$store.state.defCache.anrede" item-text="Bezeichnung" item-value="Anrede"
                              :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                              :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" dense clearable />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                    <v-text-field label="Nachname" v-model="benutzer.nachname"
                                  autocomplete="off"
                                  dense clearable />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                    <v-text-field label="Vorname" v-model="benutzer.vorname"
                                  autocomplete="off"
                                  dense clearable />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                    <v-text-field label="E-Mail*" v-model="benutzer.email"
                                  autocomplete="off" :rules="emailRules"
                                  validate-on-blur dense clearable />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="2" md="3">
                    <v-select label="Rechte*" v-model="benutzer.rechte"
                              :items="userRightsList" item-text="beschreibung" item-value="recht"
                              :rules="rechteRules"
                              :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                              :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" multiple dense clearable />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Bemerkung" v-model="benutzer.bemerkung"
                                  autocomplete="off"
                                   dense clearable />

                </v-col>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="submit"
                           :loading="isLoading" :disabled="!isInputValid || isLoading"
                           @click.stop.prevent="addNewItem(true)">

                        Benutzer hinzufügen
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-form>
        <!--<v-form v-model="isEditedValid" ref="tableForm">-->
        <v-row>
            <!-- User message -->
            <v-alert border="left" type="error" v-if="userMessage" class="mt-4">
                {{ userMessage }}
            </v-alert>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="userDataHeaders" :items="userData"
                              :footer-props="{'items-per-page-options': [5, 10, 25, 50, -1]}"
                              class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                scroll-gradient elevation-2 rounded-0 alternating-row-colors">
                    <template v-slot:item.actions="{ item }">
                        <div v-if="item.loginname === editedItem.loginname">
                            <v-icon color="red" class="mr-3" @click="close">
                                mdi-window-close
                            </v-icon>
                            <v-icon color="green" @click="addNewItem(false)" type="submit" :disabled="!isEditInputValid">
                                mdi-content-save
                            </v-icon>
                        </div>
                        <div v-else>
                            <v-icon midium
                                    class="mr-2"
                                    @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                            <!--Benutzer löschen, Status=4 setzen-->
                            <v-icon midium
                                    @click="changeItemState(item, 4)">
                                mdi-delete
                            </v-icon>
                            <v-icon v-if="item.status === 0 && item.email && item.transaktionid" midium
                                    class="ml-2"
                                    @click="verifyRegisterNewUser(item)">
                                mdi-email-send
                            </v-icon>
                            <!--Benutzer deaktivieren, Status=3 setzen -->
                            <v-icon v-if="item.status === 2" midium
                                    class="ml-2"
                                    @click="changeItemState(item, 3)">
                                mdi-lock-open
                            </v-icon>
                            <!--Benutzer wieder aktivieren, Status=2 setzen -->
                            <v-icon v-if="item.status === 3" midium
                                    class="ml-2"
                                    @click="changeItemState(item, 2)">
                                mdi-lock
                            </v-icon>
                        </div>
                    </template>
                    <template v-slot:item.anrede="{ item }">
                        <!--<v-text-field v-model="editedItem.anrede" :hide-details="true" dense single-line :autofocus="true" v-if="item.loginname === editedItem.loginname"></v-text-field>-->
                        <v-select v-model="editedItem.anrede"
                                  :items="$store.state.defCache.anrede" item-text="Bezeichnung" item-value="Anrede"
                                  :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                  :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" clearable
                                  v-if="item.loginname === editedItem.loginname" dense />
                        <span v-else>{{item.anrede}}</span>
                    </template>
                    <template v-slot:item.vorname="{ item }">
                        <v-form v-model="isEditedValid">
                            <v-text-field v-model="editedItem.vorname" dense single-line :autofocus="true" v-if="item.loginname === editedItem.loginname"></v-text-field>
                            <span v-else>{{item.vorname}}</span>
                        </v-form>
                    </template>
                    <template v-slot:item.nachname="{ item }">
                        <v-form v-model="isEditedValid">
                            <v-text-field v-model="editedItem.nachname" dense single-line :autofocus="true" v-if="item.loginname === editedItem.loginname"></v-text-field>
                            <span v-else>{{item.nachname}}</span>
                        </v-form>
                    </template>
                    <template v-slot:item.email="{ item }">
                        <v-form v-model="isEditedValid" ref="tableForm">
                            <v-text-field v-model="editedItem.email" dense single-line :rules="emailRules" validate-on-blur :autofocus="true" v-if="item.loginname === editedItem.loginname"></v-text-field>

                            <span v-else>{{item.email}}</span>
                        </v-form>
                    </template>
                    <template v-slot:item.bemerkung="{ item }">
                        <v-form v-model="isEditedValid">
                            <v-text-field v-model="editedItem.bemerkung" dense single-line :autofocus="true" v-if="item.loginname === editedItem.loginname"></v-text-field>
                            <span v-else>{{item.bemerkung}}</span>
                        </v-form>
                    </template>
                    <template v-slot:item.rechte="{ item }">
                        <v-select v-model="editedItem.rechte"
                                  :rules="rechteRules"
                                  :items="userRightsList" item-text="beschreibung" item-value="recht"
                                  :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                  :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }"
                                  v-if="item.loginname === editedItem.loginname" multiple dense clearable 
                                  return-object/>
                        <span v-else>
                            <!--<v-chip-group mandatory
                                          selected-class="text-primary">
                                <v-chip v-for="it in item.rechte" :key="it.recht">
                                    {{ it.beschreibung }}
                                </v-chip>
                            </v-chip-group>--> 
                            <div v-for="it in item.rechte" :key="it.recht"> 
                                <span v-if="it.aktiv"> {{it.beschreibung}} </span>
                                <span v-else class="inactivRight">{{it.beschreibung}} </span>
                            </div>
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <dialog-benutzer-status-aenderung :showDialog.sync="showDialogStatusAenderung" @dialogChangeState="onChangeItemState" :userItem="this.selectedUser" :newStatus="this.newStatus" />
        <dialog-benutzer-einladung :showDialog.sync="showDialogEinladung" @dialogSendInvitation="onSendInvitation" :userItem="this.selectedUser"/>

    </v-container>
</template>

<script>
    import Benutzerverwaltung from '@/components/Benutzerverwaltung.vue'
    import axios from 'axios'
    import eventBus from '@/main';
    import DialogBenutzerStatusAenderung from '@/components/DialogBenutzerStatusAenderung.vue'
    import DialogBenutzerEinladung from '@/components/DialogBenutzerEinladung.vue'

    export default {
        name: 'TabRetaxen',

        components: {
            Benutzerverwaltung,
            DialogBenutzerStatusAenderung,
            DialogBenutzerEinladung
        },

        data() {
            return {
                userDataHeaders: [
                    { text: 'Benutzername', value: 'loginname', align: 'start' },
                    { text: 'Anrede', value: 'anrede', align: 'start' },
                    { text: 'Nachname', value: 'nachname', align: 'start' },
                    { text: 'Vorname', value: 'vorname', align: 'start' },
                    { text: 'E-Mail', value: 'email', align: 'start' },
                    { text: 'Rechte', value: 'rechte', align: 'start' },
                    { text: 'Status', value: 'statuserlaeuterung', align: 'start' },
                    { text: 'Bemerkung', value: 'bemerkung', align: 'start' },
                    { text: '', value: 'actions', sortable: false, align: 'end' },
                ],
                userData: [],
                editedItem: {},
                orgItem: {},
                isEditedValid: true,
                anrede: [
                    { Anrede: 'Herr', Bezeichnung: 'Herr' },
                    { Anrede: 'Frau', Bezeichnung: 'Frau' },
                    { Anrede: 'Divers', Bezeichnung: 'Divers' },
                ],
                defaultBenutzer: {},
                benutzer: {
                    loginname: null,
                    anrede: '',
                    nachname: '',
                    vorname: '',
                    email: '',
                    bemerkung: '',
                    rechte: [],
                },
                showDialog: false,
                showPasswort: false,
                isValid: true,
                password: null,
                showDialogStatusAenderung: false,
                showDialogEinladung: false,
                selectedUser: {},
                newStatus: null,
                defaultItem: {},
                userRightsList: [],
                userAllRightsList: [],
                emailRules: [
                    v => !!v || 'Bitte geben Sie eine gültige Email-Adresse ein',
                    v => /.+@.+\..+/.test(v) || 'Geben Sie bitte eine gültige Email-Adresse ein',
                    v => this.checkUniqueEmail(v) || 'Für jedes Benutzerkonto muss eine eigene eindeutige E-Mail-Adresse hinterlegt werden'
                ],
                rechteRules: [
                    v => Boolean(Object.keys(v || {})[0]) || 'Bitte mindestens ein Benutzerrecht vergeben',
                    v => this.checkWriteRight(v) || 'Zum Schreiberecht muss auch Leserecht erteilt werden'
                    // v =>  !Object.keys(v).includes('1')  || 'Beim Schreiberecht bitte auch Leserecht auswählen'
                ],
                isLoading: false,
                verificationSuccess: false,
                userMessage: '',
            };
        },

        async mounted() {
            await this.initData();

            this.$nextTick(() => {
                this.getBenutzerListe();

            });
        },

        created() {
            this.initData();
            this.defaultBenutzer = Object.assign({}, this.benutzer);
        },

        computed: {

            getselection() {
                return this.selected.value
            },

            currentLE() {
                return this.$store.state.currentUser?.leistungserbringer;
            },

            isInputValid: function () {
                return this.isValid
                    && this.benutzer.rechte.length > 0;

            },

            isEditInputValid: function () {
                //console.log('this.editedItem!== this.orgItem', this.editedItem !== this.orgItem, JSON.stringify(this.editedItem) !== JSON.stringify(this.orgItem) )
                return this.isEditedValid
                    && this.editedItem.rechte.length > 0
                    && JSON.stringify(this.editedItem) !== JSON.stringify(this.orgItem);

            },
        },

        methods: {
            initData() {
                this.showDialogEinladung = false;
                this.selectedUser = {};
                this.userMessage = '';
                this.getBenutzerRechteListe();
            },

            editItem(item) {
                this.editedIndex = this.userData.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.orgItem = Object.assign({}, this.editedItem);
                console.log('editItem(item), this.editedItem', this.editedItem )
            },

            checkWriteRight(item) {
                let it = Object.values(item);
                if (it.length > 0 && it.includes(2))
                    return it.includes(1)
                if (it.length > 0 && it.includes(8))
                    return it.includes(7)

                return true;
                //return it.length > 0 && it.includes(2) ? it.includes(1) : true;
            },

            checkUniqueEmail(item) {
                let ix = -1

                if (this.editItem)
                    ix = this.userData.findIndex(i => i.email.trim() === item.trim() && this.editedItem.loginname !== i.loginname);
                else
                    ix = this.userData.findIndex(i => i.email.trim() === item.trim());
                return ix !== -1 ? false : true;
            },

            changeItemState(item, status) {

                this.selectedUser = item;
                this.newStatus = status;
                this.showDialogStatusAenderung = true;  //confirm('Are you sure you want to delete this item?') && this.userData.splice(index, 1);
            },

            onSendInvitation(value) { 

                if (value) {
                    this.verifyRegisterNewUser(this.selectedUser);
                    //this.selectedUser = {};
                    this.benutzer = Object.assign({}, this.defaultBenutzer);
                    //console.log('this.benutzer nachher', this.benutzer);
                    this.$refs.bvform.reset();
                    this.showDialogEinladung = false;
                    //return;
                }
                else { 
                    this.benutzer = Object.assign({}, this.defaultBenutzer);
                    //console.log('this.benutzer nachher', this.benutzer);
                    this.$refs.bvform.reset();
                    this.showDialogEinladung = false;
                    this.selectedUser = {};
                }
            },
           
            async onChangeItemState(value) {
                console.log('this.selectedUser.loginname', this.selectedUser.loginname)

                eventBus.$emit('resetLogoutTimer', {});

                if (value) {
                    const index = this.userData.indexOf(this.selectedUser);
                    try {
                        this.loading = true;
                        let response = await this.$http.post('benutzerverwaltung/benutzerstatus', {
                            Benutzer: this.$store.state.currentUser.loginName,
                            BenutzerMA: parseInt(this.selectedUser.loginname, 10),
                            Status: this.newStatus
                        });

                        if (response.status === 200) {
                            if (this.newStatus === 4) this.userData.splice(index, 1)
                            else this.getBenutzerDaten(this.selectedUser);

                            this.newStatus = null;
                            this.selectedUser = {};
                        }
                    }
                    catch (error) {
                        if (error.response) {
                            this.userMessage = error.response.data.message;
                        }
                    }
                    finally {
                        this.loading = false;
                    }
                }

                this.showDialogStatusAenderung = false;
                this.selectedUser = {};
            },

            close() {
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300)

                this.showDialog = false;
                this.password = '';
            },

            async sendMail(verCode) {
                try {
                    this.isLoading = true;

                    // verifyCaptcha hat eigene Fehlerbehandlung mit separatem Dialog
                    let captchaOk = await this.verifyCaptcha();
                    if (captchaOk)
                        await this.register(verCode);
                }
                catch (err) {
                    this.showErrorInDialog(err);
                }
                finally {
                    this.isLoading = false;
                }
            },

            async verifyRegisterNewUser(item) {
                eventBus.$emit('resetLogoutTimer', {});

                if (item.status === 0 && item.email && item.transaktionid) {
                    try {
                        this.isLoading = true;
                        const loginname = this.$store.state.currentUser?.loginName;

                        // register verification request in backend
                        let rsp = await this.$http.post('auth/verifyregisternewuser', { loginName: loginname, RegCode: item.transaktionid, Email: item.email });
                        if (rsp && rsp.status === 200) {
                            this.verificationSuccess = true;
                            this.userMessage = '';
                            await this.getBenutzerDaten(item);
                        }
                    }
                    catch (err) {
                        let msg = err.message;
                        if (err.response && err.response.data && err.response.data.message)
                            msg = err.response.data.message;
                        this.userMessage = msg;
                    }
                    finally {
                        this.selectedUser = {};
                        this.showDialogEinladung = false;
                        this.isLoading = false;
                    }
                }
            },

            async verifyCaptcha() {
                try {
                    // get captcha token
                    await this.$recaptchaLoaded();
                    let token = await this.$recaptcha('register');
                    let rsp = await this.$http.get("auth/verifycaptcha", { params: { token: token } });

                    if (rsp.status !== 200)
                        throw new Error('Ungültiges Captcha');

                    return true;
                }
                catch (err) {
                    this.showDialogCaptchaFailure = true;
                    return false;
                }
            },

            async addNewItem(bNeu) {

                try {
                    if (!bNeu) this.benutzer = Object.assign({}, this.defaultBenutzer);

                    eventBus.$emit('resetLogoutTimer', {});

                    let saveItem = {};
                    //Wenn es sich um einen neuen Benutzer handelt, dann sollen die Daten aus benutzer gepeichert werden, sonst aus dem editierten Item in der Tabelle
                    if (bNeu) {
                        Object.assign(saveItem, this.benutzer);
                        console.log('saveitem: ', saveItem, 'this.benutzer: ', this.benutzer);

                    } else if (this.editedItem != this.orgItem) {
                        Object.assign(saveItem, this.editedItem);
                        console.log('saveitem: ', saveItem, 'this.editedItem: ', this.editedItem);
                    }
                    const bRechte = bNeu ? this.benutzer.rechte.join(',') : this.editedItem.rechte.map(r => r.recht).join(',');  //this.editedItem.rechte.join(',');

                    const le = this.$store.state.currentUser.leistungserbringer;

                    await this.$http.post('benutzerverwaltung/benutzer', {
                        Benutzer: le,
                        BenutzerNeu: saveItem.loginname ? saveItem.loginname : null,
                        Anrede: saveItem.anrede,
                        Nachname: saveItem.nachname,
                        Vorname: saveItem.vorname,
                        Bemerkung: saveItem.bemerkung,
                        Email: saveItem.email,
                        Rechte: bRechte
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data !== null) {
                                var newUser = response.data;
                                var ur = newUser.rechte.split(",").map(Number);
                                var obj = ur.map(r => {
                                    var beschreibung = this.userAllRightsList.find(i => i.recht === r);
                                    console.log('beschreibung', beschreibung);
                                    let properties = {
                                        "recht": r,
                                        "beschreibung": beschreibung ? beschreibung.beschreibung : '',
                                         "aktiv": this.findUserRight(r)  
                                    }
                                    return properties;
                                });

                                var updUser = {
                                    loginname: newUser.loginName,
                                    anrede: newUser.anrede,
                                    nachname: newUser.nachname,
                                    vorname: newUser.vorname,
                                    email: newUser.eMail,
                                    bemerkung: newUser.bemerkung,
                                    status: newUser.status,
                                    statuserlaeuterung: newUser.statusErlaeuterung,
                                    rechte: obj,
                                    transaktionid: newUser.transaktionID
                                }

                                if (bNeu) {

                                    this.userData.unshift(updUser);

                                    console.log('this.userData: ', this.userData);
                                    this.selectedUser = this.userData.find(u => u.loginname === newUser.loginName);

                                    console.log('this.selectedUser: ', this.selectedUser);

                                    if (this.selectedUser)
                                        this.showDialogEinladung = true;
                                }
                                else {
                                    Object.assign(this.userData[this.editedIndex], updUser)
                                }
                                //this.userData.push(response.data);
                                console.log(response);
                                this.close();
                            }
                            /*
                            this.benutzer = Object.assign({}, this.defaultBenutzer);
                            //console.log('this.benutzer nachher', this.benutzer);
                            this.$refs.bvform.reset();
                            */
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.userMessage = error.response.data.message;
                        }
                    });
                }
                catch (error) {
                    this.userMessage = error.message;
                }
            },

            async getBenutzerDaten(item) {
                //this.isLoading = true;
                const le = this.$store.state.currentUser.leistungserbringer;
                await this.$http.get('/benutzerverwaltung').then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            var updateditem = response.data.find(d => d.loginName === item.loginname)
                            if (updateditem) {
                                var ur = updateditem.rechte.split(",").map(Number);
                                var obj = ur.map(r => {
                                    var beschreibung = this.userAllRightsList.find(i => i.recht === r);
                                    console.log('beschreibung', beschreibung);
                                    let properties = {
                                        "recht": r,
                                        "beschreibung": beschreibung ? beschreibung.beschreibung : '',
                                        "aktiv": this.findUserRight(r)  
                                    }
                                    return properties;
                                });
                                var updUser = {
                                    loginname: updateditem.loginName,
                                    anrede: updateditem.anrede,
                                    nachname: updateditem.nachname,
                                    vorname: updateditem.vorname,
                                    email: updateditem.eMail,
                                    bemerkung: updateditem.bemerkung,
                                    status: updateditem.status,
                                    statuserlaeuterung: updateditem.statusErlaeuterung,
                                    rechte: obj,
                                    transaktionid: updateditem.transaktionID
                                }
                                var ix = this.userData.findIndex(d => d.loginname === item.loginname);
                                if (ix != -1) Object.assign(this.userData[ix], updUser)
                            }
                            console.log('this.userData: ', this.userData);
                        }
                    }
                }).catch(error => {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                    }
                });
            },

            async getBenutzerListe() {
                this.isLoading = true;
                eventBus.$emit('resetLogoutTimer', {});
                this.userData = [];
                this.selectedUser = {};

                const le = this.$store.state.currentUser.leistungserbringer;
                await this.$http.get('/benutzerverwaltung').then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            let vm = this;
                            response.data.forEach(x => {
                                var ur = x.rechte.split(",").map(Number);
                                var obj = ur.map(r => {
                                    var beschreibung = this.userAllRightsList.find(i => i.recht === r);
                                    console.log('beschreibung', beschreibung);
                                    let properties = {
                                        "recht": r,
                                        "beschreibung": beschreibung ? beschreibung.beschreibung : '',
                                        "aktiv": this.findUserRight(r)  
                                    }
                                    return properties;
                                });
                                console.log('obj', obj);

                                //if (x.status !== 4)
                                this.userData.push({
                                    loginname: x.loginName,
                                    anrede: x.anrede,
                                    nachname: x.nachname,
                                    vorname: x.vorname,
                                    email: x.eMail,
                                    bemerkung: x.bemerkung,
                                    status: x.status,
                                    statuserlaeuterung: x.statusErlaeuterung,
                                    rechte: obj, //x.rechte.split(",").map(Number)
                                    transaktionid: x.transaktionID
                                });
                            });
                            console.log('this.userData: ', this.userData);
                            
                            //this.selectedUser = this.userData.find(u => u.loginname === "300000077009");
                            //console.log('this.selectedUser: ', this.selectedUser)
                            //this.showDialogEinladung = true;
                        }
                    }
                }).catch(error => {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                    }
                }).finally(
                    this.isLoading = false
                );
            },

            findUserRight(i) {
                //Benutzerrecht finden
                
                //let ix = this.$store.state.currentUser.rechte.findIndex(r => r.recht === i);
                let ix = this.userRightsList.findIndex(r => r.recht === i);
                return ix !== -1
            },

            async getBenutzerRechteListe() {
                this.isLoading = true;
                this.userRightsList = [];
                this.selectedUser = {};

                const le = this.$store.state.currentUser.leistungserbringer;
                await this.$http.get('/benutzerverwaltung/rechte', {
                    params: {
                        Leistungserbringer: le,
                        Art: 1, // Nur die Liste der Rechte für die normalen Benutzer wird angezeigt
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            let rsp = response.data;
                            this.userAllRightsList = rsp;
                            rsp.forEach(r => {
                                switch (r.recht) {
                                    case 1:
                                        if (this.$store.state.module.retaxRead) this.userRightsList.push(r);
                                        break;
                                    case 2:
                                        if (this.$store.state.module.retaxWrite) this.userRightsList.push(r);
                                        break;
                                    case 3:
                                        if (this.$store.state.module.zuzahlungRead ) this.userRightsList.push(r);
                                        break;
                                    case 7:
                                        if (this.$store.state.module.zzRueckforderungRead ) this.userRightsList.push(r);
                                        break;
                                    case 8:
                                        if (this.$store.state.module.zzRueckforderungWrite ) this.userRightsList.push(r);
                                        break;
                                }
                                //if (this.findUserRight(r.recht))
                                //    this.userRightsList.push(r);
                            })

                           // this.userRightsList = response.data;
                            console.log('this.userRightsList: ', this.userRightsList);
                        }
                    }
                }).catch(error => {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                    }
                }).finally(
                    this.isLoading = false
                );
            },

        },
    }
</script>
<style scoped>


    /*#region footer in v-data-table nur bei xs wrappen*/
    >>> .v-data-footer {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    @media (min-width: 600px) {
        >>> .v-data-footer {
            flex-wrap: nowrap;
        }
    }
    /*#endregion*/

    /*#region Bei mobile-Ansicht select-Checkbox in erster Zeile links ausrichten */
    >>> .v-data-table--mobile .v-data-table__mobile-row:first-child {
        flex-direction: row-reverse;
    }
    /*#endregion*/

    .inactivRight {
        color: #999999;
    }

</style>