<template>
    <v-container fluid class="pa-0">
        <v-row v-if="$store.state.currentUser">
            <v-col cols="12" sm="12" md="10">

                <v-alert type="error" class="d-inline-block" outlined text>
                    <p class="text-h6">
                        Sind Sie sicher, dass Sie Ihr Konto für das Service-Portal kündigen wollen?
                    </p>
                    <p class="text-body-1">
                        Im Falle einer Kündigung
                        <ul>
                            <li>werden Ihnen Absetzungsschreiben ab dem Zeitpunkt der Wirksamwerdung der Kündigung wieder postalisch zugestellt</li>
                            <li>müssen Sie Einsprüche wieder schriftlich einreichen</li>
                            <li>verlieren Sie die Möglichkeit, jederzeit den Bearbeitungsstand Ihrer Einsprüche nachvollziehen zu können</li>
                            <li>haben Sie nicht mehr die Möglichkeit, die Zuzahlungspflicht direkt zu überprüfen</li>
                            <li>können Sie sich ab dem Zeitpunkt der Wirksamwerdung der Kündigung nicht mehr im Portal anmelden</li>
                        </ul>
                    </p>
                    <p class="text-h6 mb-0">
                        Ihre Kündigung würde wirksam zum {{ kuendigungsdatum.format('DD.MM.YYYY') }}.
                    </p>
                </v-alert>
                <p class="text-h6 mt-8 mb-3">
                    Wenn Sie sicher sind, dann bestätigen Sie bitte durch folgende Angaben:
                </p>
                <v-form ref="form" v-model="isValid">

                    <!-- Password forms should have (optionally hidden) username fields for accessibility -->
                    <v-text-field label="Benutzername" :value="$store.state.currentUser.benutzer"
                                    autocomplete="username" v-show="false" readonly />

                    <v-text-field label="Passwort" v-model="vPassword"
                                  autocomplete="current-password"
                                  :type="showPasswort ? 'text' : 'password'"
                                  :rules="[v => !!v || 'Bitte geben Sie das Passwort ein']"
                                  :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                  @click:append="() => (showPasswort = !showPasswort)"
                                  class="d-inline-flex mr-4" required />

                    <verify-otp @verifyOtpDone="(rst) => { this.jwt = rst; }"
                                class="d-inline-flex" show-label />

                    <v-btn color="error" :disabled="!isValid || this.jwt === null" 
                           class="d-block mt-4" @click="deleteAccount">
                    
                        Konto löschen
                    </v-btn>

                </v-form>

            </v-col>
        </v-row>

        <v-dialog v-model="showError">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.error</v-icon>
                    Fehler
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    {{ unregisterError }}
                </v-card-text>
                <v-card-actions>
                    <spacer />
                    <v-btn @click="showError = false" color="primary" text>
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-konto-loeschen :showDialog.sync="showDialogKontoLoeschen" />

    </v-container>
</template>

<script>

    import VerifyOtp from '@/components/VerifyOtp.vue';
    import eventBus from '@/main';
    import DialogKontoLoeschen from '@/components/DialogKontoLoeschen.vue';

    export default {

        name: 'KontoLoeschen',

        components: {
            VerifyOtp,
            DialogKontoLoeschen
        },

        data() {
            return {
                vPassword: '',
                showPasswort: false,
                isValid: false,
                jwt: null,
                showError: false,
                errorCount: 0,
                unregisterError: '',
                showDialogKontoLoeschen: false,
            }
        },

        computed: {
            kuendigungsdatum: function () {
                return this.moment().add(2, 'M').endOf("month");
            }
        },

        methods: {
            deleteAccount() {
                let param = { Password: this.vPassword, Jwt: this.jwt };
                this.$http.post('auth/unregister', param)
                    .then(response => {
                        if (response && response.status === 200) {

                            let currentUser = this.$store.state.currentUser;
                            currentUser.gbDatum = this.kuendigungsdatum;
                            this.$store.commit('updateCurrentUser', currentUser);

                            this.showDialogKontoLoeschen = true;
                            this.$refs.form.reset();
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            this.$refs.form.reset();
                            this.showError = true;
                            this.unregisterError = error.response.data.message;
                            this.errorCount += 1;

                            if (this.errorCount < 3) {
                                this.unregisterError += "<br />Bitte geben sie Ihr Passwort erneut ein.";
                            }
                            else {
                                this.unregisterError += "<br />Sie haben das Passwort dreimal falsch eingegeben. Sie werden nun abgemeldet.";
                                setTimeout(() => {
                                    eventBus.$emit('logout');
                                }, 5000);
                            }
                        }
                    });
            },
        }
    }
</script>