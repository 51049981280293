<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                <v-icon color="primary" large left>mdi-account</v-icon>
                Ein neuer Benutzer wurde angelegt
            </v-card-title>
            <v-card-text class="text-body-1 text--primary">
                <p v-if="userItem" class="text-justify text-hyphenate mb-0">
                    Das Konto mit dem <strong>Benutzernamen: {{userItem.loginname}}</strong> wurde für den Benutzer <strong>{{userItem.vorname}} {{userItem.nachname}}</strong> angelegt. 
                    <br>In der Benutzerübersicht haben Sie die Möglichkeit Änderungen vorzunehmen, das Konto zu deaktivieren (inaktiv zusetzen) oder das Konto zu löschen.
                <p/>
                <p><strong>Wie geht es weiter?</strong></p>
                <p>
                    Als nächsten Schritt der Registrierung senden wir dem neuen Benutzer eine E-Mail mit einem Link zur Verifizierung der vergebenen E-Mail-Adresse zu. Diesen Link muss der Benutzer anklicken, um die Registrierung abzuschließen.
                </p>
                <p>Anschließend vergibt der neue Benutzer ein persönliches Passwort und richtet für die benötigte Zwei-Faktor-Authentifizierung ein Mobilgerät mit einer installierten Authenticator-App ein - sofern ein weiteres Gerät hierfür verwendet wird. Wird ein bereits vorhandenes Mobilgerät verwendet, entfällt dieser Schritt.</p>
                <p>Für jede Anmeldung im Service-Portal für Apotheken benötigt der Benutzer den vergebenen <strong> Benutzernamen</strong> (siehe oben), das <strong> Passwort </strong> und den mit der Authenticator-App generierte <strong> sechsstellige Zahlencode</strong>.</p>
                <p>Wollen Sie den neuen Benutzer gleich zur Registrierung einladen?</p>
                <p>
                    Sie können dies auch zu einem späteren Zeitpunkt vornehmen. Hierzu können Sie in der Benutzerübersicht den Button <v-icon midium>mdi-email-send</v-icon> anklicken und dem Benutzer wird die entsprechende E-Mail übermittelt.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="onCancel">
                    später einladen
                </v-btn>
                <v-btn color="primary" @click="onVerifyRegistration">
                    gleich einladen
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'DialogBenutzerEinladung',
        props: {
            showDialog: false, 
            userItem: {}
        },
        methods: {
            onVerifyRegistration() {

                this.$emit('dialogSendInvitation', this.userItem);
  
            },

            onCancel() {
                this.$emit('dialogSendInvitation', false);
            }
        },
    }
</script>