<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                <v-icon color="primary" large left>$vuetify.icons.delete</v-icon>
                Konto löschen
            </v-card-title>
            <v-card-text class="text-body-1 text--primary">
                <p class="text-justify text-hyphenate mb-0">
                    Ihr Konto wird dem Kündigungstermin entsprechend zum <span class="font-weight-bold">{{ kuendigungstermin.format('DD.MM.YYYY') }}</span> 
                    gelöscht. Falls Sie das Service-Portal zu einem späteren Zeitpunkt erneut nutzen möchten, müssen Sie sich neu registrieren.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="onDialogClose">
                    Schließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'DialogKontoLoeschen',
        props: {
            showDialog: false,
        },
        computed: {
            kuendigungstermin: function () {
                return this.moment().add(2, 'M').endOf("month");
            }
        },
        methods: {
            onDialogClose: function () {

                let currentUser = this.$store.state.currentUser;
                currentUser.gbDatum = this.kuendigungstermin.toDate();
                this.$store.commit('updateCurrentUser', currentUser);

                this.showDialog = false;
                this.$router.push('/');
            },
        },
    }
</script>