import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 text-md-h4 flex-nowrap align-start"},[_c(VIcon,{attrs:{"color":"primary","large":"","left":""}},[_vm._v("$vuetify.icons.delete")]),_vm._v(" Konto löschen ")],1),_c(VCardText,{staticClass:"text-body-1 text--primary"},[_c('p',{staticClass:"text-justify text-hyphenate mb-0"},[_vm._v(" Ihr Konto wird dem Kündigungstermin entsprechend zum "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.kuendigungstermin.format('DD.MM.YYYY')))]),_vm._v(" gelöscht. Falls Sie das Service-Portal zu einem späteren Zeitpunkt erneut nutzen möchten, müssen Sie sich neu registrieren. ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.onDialogClose}},[_vm._v(" Schließen ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }